import { useRef, useEffect, useState } from "react"
import ReCAPTCHA from "react-google-recaptcha"
import { useRouter } from "next/router"
import Loading from "@/components/common/loading/logo-with-text"
import { useAppContext } from "@/lib/contexts/appContext"
import { useArchiveContext } from "@/lib/contexts/archiveContext"
import { tickSearchClick } from "@/lib/api/search"
import ArticleCard from "@/common/cards/article/article"
import EventCard from "@/common/cards/event/event"
import Paging from "@/common/paging/paging"
import Block from "@/components/wrappers/block"
import styles from "./archive-articles.module.css"

export default function ArchiveArticles({ block }) {
  const store = useArchiveContext()
  const appContext = useAppContext()
  const router = useRouter()
  const recaptchaRef = useRef()
  const siteKey = process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY
  const currentPage = store.getPage()

  const trackClick = async (e, url) => {
    e.preventDefault()
    const captcha = await recaptchaRef.current.executeAsync()
    tickSearchClick(captcha, store?.hash)
    router.push(url)
  }

  return (
    <Block block={block} width="full" innerWrapper={true}>
      <ReCAPTCHA
        className="hidden"
        ref={recaptchaRef}
        size="invisible"
        sitekey={siteKey}
      />
      <div className="pb-20 bg-yellow-30">
        <Content block={block} trackClick={trackClick} />
      </div>
      {!store.loading && (
        <div className="text-center">
          <Paging
            currentPage={currentPage}
            maxPage={store?.maxPage}
            elemRef={appContext?.searchFormRef}
            setPage={(page) => store.setPage(page)}
          />
        </div>
      )}
    </Block>
  )
}

export const Content = ({ block, trackClick }) => {
  const store = useArchiveContext()
  const router = useRouter()
  const [heading, setHeading] = useState(
    block?.attrs?.subheadline.replace("{{count}}", store.totalCount)
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    const afterSearch =
      store.totalCount === 1
        ? block?.attrs?.afterSearchOne ?? ""
        : block?.attrs?.afterSearch ?? ""

    const text = searchParams.size > 0 ? afterSearch : block.attrs.subheadline

    setHeading(text.replace("{{count}}", store.totalCount))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router])

  if (store.loading) {
    return (
      <div className={styles.wrapper}>
        <Loading />
      </div>
    )
  }

  if (!store.loading && store.list.length === 0) {
    return (
      <div className={styles.wrapper}>
        <h2>Hittade ingenting</h2>
        <p>Prova att söka på något annat.</p>
      </div>
    )
  }

  const items = store.list.map((post) => {
    let Card = ArticleCard
    switch (store.cardType) {
      case "event":
        Card = EventCard
        break

      default:
        break
    }

    return (
      <li key={post.url}>
        <Card post={post} trackClick={trackClick} />
      </li>
    )
  })

  return (
    <div className={styles.result}>
      <h2>{heading}</h2>
      <ul>{items}</ul>
    </div>
  )
}
